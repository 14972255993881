.custom-contact-button {
    background-color: #B84644;
    color: white;
    padding: 10px !important;
    padding-left: 20px;
    padding-right: 20px;
    border-color: #B84644;
}

.custom-contact-button:hover {
    background-color: #B84644;
    border-color: #B84644;
}