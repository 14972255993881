.last-custom-button {
    border-radius: 5px;
    background-color: #4a4a4a;
    color: white;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-color: #B84644;
}

.last-custom-button:hover {
    background-color: #B84644;
    border-color: #B84644;
}

/* Contenedor principal de la tarjeta */
.last-card-container {
    width: 250px;
    /* Define un ancho consistente */
    height: 400px;
    /* Altura fija */
    overflow: hidden;
}

/* Contenido de la tarjeta */
.last-card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

/* Contenedor de la imagen */
.last-image-container {
    height: 50%;
    /* Ocupa la mitad de la tarjeta */
    width: 100%;
    overflow: hidden;
}

.last-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Escala sin deformar */
    border-radius: 5px;
}

/* Contenedor del texto */
.last-text-container {
    height: 50%;
    /* Ocupa la mitad restante */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    text-align: center;
}

/* Título del texto */
.last-text-title {
    font-size: 1rem;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* Cambia a normal si quieres líneas múltiples */
}

.last-left-arrow,
.last-right-arrow {
    position: absolute;
    border: none;
    z-index: 99;
    background-color: rgb(184, 70, 68, .8);
    font-size: 100%;
    border-radius: 100%;
    aspect-ratio: 1/1;
    width: 4%;
    color: white;
    cursor: pointer;
}

.last-left-arrow {
    left: 0;
}
.last-right-arrow {
    right: 0;
}