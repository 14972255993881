.home-gallery .image-gallery-icon {
    color: #fff;
    transition: all .3s ease-out;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    filter: drop-shadow(0 2px 2px #1a1a1a)
}

@media(hover: hover)and (pointer: fine) {
    .home-gallery .image-gallery-icon:hover {
        color: #B84644
    }

    .home-gallery .image-gallery-icon:hover .image-gallery-svg {
        transform: scale(1.1)
    }
}

.home-gallery .image-gallery-icon:focus {
    outline: 2px solid #B84644
}

.home-gallery .image-gallery-using-mouse .image-gallery-icon:focus {
    outline: none
}

.home-gallery .image-gallery-fullscreen-button,
.home-gallery .image-gallery-play-button {
    bottom: 0;
    padding: 20px
}

.home-gallery .image-gallery-fullscreen-button .image-gallery-svg,
.home-gallery .image-gallery-play-button .image-gallery-svg {
    height: 28px;
    width: 28px
}

@media(max-width: 768px) {

    .home-gallery .image-gallery-fullscreen-button,
    .home-gallery .image-gallery-play-button {
        padding: 15px
    }

    .home-gallery .image-gallery-fullscreen-button .image-gallery-svg,
    .home-gallery .image-gallery-play-button .image-gallery-svg {
        height: 24px;
        width: 24px
    }
}

@media(max-width: 480px) {

    .home-gallery .image-gallery-fullscreen-button,
    .home-gallery .image-gallery-play-button {
        padding: 10px
    }

    .home-gallery .image-gallery-fullscreen-button .image-gallery-svg,
    .home-gallery .image-gallery-play-button .image-gallery-svg {
        height: 16px;
        width: 16px
    }
}

.home-gallery .image-gallery-fullscreen-button {
    right: 0
}

.home-gallery .image-gallery-play-button {
    left: 0
}

.home-gallery .image-gallery-left-nav,
.home-gallery .image-gallery-right-nav {
    padding: 50px 10px;
    top: 50%;
    transform: translateY(-50%)
}

.home-gallery .image-gallery-left-nav .image-gallery-svg,
.home-gallery .image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 60px
}

@media(max-width: 768px) {

    .home-gallery .image-gallery-left-nav .image-gallery-svg,
    .home-gallery .image-gallery-right-nav .image-gallery-svg {
        height: 72px;
        width: 36px
    }
}

.home-gallery .image-gallery-left .image-gallery-right {
    height: 100%;
}

@media(max-width: 480px) {

    .home-gallery .image-gallery-left-nav .image-gallery-svg,
    .home-gallery .image-gallery-right-nav .image-gallery-svg {
        height: 72px;
        width: 36px
    }
}

.home-gallery .image-gallery-left-nav[disabled],
.home-gallery .image-gallery-right-nav[disabled] {
    cursor: disabled;
    opacity: .6;
    pointer-events: none
}

.home-gallery .image-gallery-left-nav {
    left: 0
}

.home-gallery .image-gallery-right-nav {
    right: 0
}

.home-gallery .image-gallery {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: relative
}

.home-gallery .image-gallery.fullscreen-modal {
    background: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5
}

.home-gallery .image-gallery.fullscreen-modal .image-gallery-content {
    top: 50%;
    transform: translateY(-50%)
}

.home-gallery .image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0;
    height: 500px;
}

.home-gallery .image-gallery{
    height: 100%;
}

.home-gallery .image-gallery-swipe {
    height: 100%;
}

.home-gallery .image-gallery-content.fullscreen {
    background: #000
}

.home-gallery .image-gallery-content {
    max-height: calc(100% - 80px)
}

.home-gallery .image-gallery-content.image-gallery-thumbnails-left .image-gallery-slide .image-gallery-image,
.home-gallery .image-gallery-content.image-gallery-thumbnails-right .image-gallery-slide .image-gallery-image {
    max-height: 100vh
}

.home-gallery .image-gallery-slide-wrapper {
    position: relative;
    height: 100%;
}

.home-gallery .image-gallery-slide-wrapper.image-gallery-thumbnails-left,
.home-gallery .image-gallery-slide-wrapper.image-gallery-thumbnails-right {
    display: inline-block;
    width: calc(100% - 110px)
}

@media(max-width: 768px) {.home-gallery 

    .image-gallery-slide-wrapper.image-gallery-thumbnails-left,
    .image-gallery-slide-wrapper.image-gallery-thumbnails-right {
        width: calc(100% - 87px)
    }
}

.home-gallery .image-gallery-slide-wrapper.image-gallery-rtl {
    direction: rtl
}

.home-gallery .image-gallery-slides {
    line-height: 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-align: center;
    height: 100%;
}

.home-gallery .image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.home-gallery .image-gallery-slide.image-gallery-center {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.home-gallery .image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: cover;
    height: 100%;
    
}

.home-gallery .image-gallery-slide .image-gallery-description {
    background: rgba(0, 0, 0, .4);
    bottom: 70px;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal;
    width: 100%;
    font-size: 1.2em;
}

@media(max-width: 768px) {
    .image-gallery-slide .image-gallery-description {
        bottom: 45px;
        font-size: .8em;
        padding: 8px 15px;
        width: 100%;
    }
}