.landing {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    /* background-image: linear-gradient(180deg, #7a2757 10%, #632f6b 90%); */
}

.loginCard {
    width: 290px;
    padding: 1.9rem 1.2rem;
    text-align: center;
    border-radius: 4px;
    background: #a4a4a4;
}

/*Inputs*/
.field {
    margin-top: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5em;
    background-color: #31252C;
    border-radius: 4px;
    padding: .5em 1em;
}

.input-icon {
    height: 1em;
    width: 1em;
    fill: #ffeba7;
}

.input-field {
    background: none;
    border: none;
    outline: none;
    width: 100%;
    color: #9E7F91;
}

/*Text*/
.title {
    margin-bottom: 1rem;
    font-size: 1.5em;
    font-weight: 500;
    color: #1f2029;
}

/*Buttons*/
.loginbtn {
    margin: 1rem;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: .8em;
    text-transform: uppercase;
    padding: 0.6em 1.2em;
    background-color: #31252C;
    color: #5e6681;
    box-shadow: 0 8px 24px 0 rgb(255 235 167 / 20%);
    transition: all .3s ease-in-out;
}

/*Hover & focus*/
.field input:focus::placeholder {
    opacity: 0;
    transition: opacity .3s;
}

.btn:hover {
    background-color: #5e6681;
    color: #ffeba7;
    box-shadow: 0 8px 24px 0 rgb(16 39 112 / 20%);
}

.btn-link:hover {
    color: #ffeba7;
}
